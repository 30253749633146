import React, { useEffect } from "react"
import styled from "styled-components"
import { Link } from "gatsby"

// components
import BlogFooter from "../components/blog/BlogFooter"

// importing images
import hostparty from "../images/blog/host.png"
import queuesongs from "../images/blog/queue-songs.png"
import thequeue from "../images/blog/thequeue.png"

const MusicBlog = () => {
  useEffect(() => {
    if (window.analytics) {
      window.analytics.track("Visited Music Guide")
    }
  }, [])

  return (
    <PageWrapper>
      <Wrapper>
        <BlogHeader>
          <Yellow>Guide:</Yellow> Play music with Queue🔈
        </BlogHeader>
        <Teaser>
          Always play the most loved music without stressing! Let your guests
          add and upvote songs in queue.
        </Teaser>

        <Section>
          <HeaderContainer>
            <Header>Step 1: Create a party</Header>
            <YellowBar />
          </HeaderContainer>
          <Paragraph>
            Open Queue, click ‘Host Party’ and ‘Party Now’. You have now created
            a party!!
          </Paragraph>
          <ImageContainer>
            <Image1 />
          </ImageContainer>
        </Section>

        <Section>
          <HeaderContainer>
            <Header>Step 2: Add songs</Header>
            <YellowBar />
          </HeaderContainer>
          <Paragraph>
            Open Spotify, click ‘See Queue’, ‘+’ at the very bottom of the
            screen and add your favorite songs.
          </Paragraph>
          <ImageContainer>
            <Image2 />
          </ImageContainer>
        </Section>

        <Section>
          <HeaderContainer>
            <Header>Step 3: Upvote songs</Header>
            <YellowBar />
          </HeaderContainer>
          <Paragraph>
            Click the 'finger' emoji on a song to upvote it. Then the song will
            be played even sooner.
            <br />
            <br />
            As a host, you can also always skip and pause music, while your
            guests can only add and upvote songs😋
          </Paragraph>

          <Paragraph>
            Finished the party? Tap the 'x' on the party home screen and click
            “Stop Party”.
          </Paragraph>
        </Section>

        <More>
          <BoldParagraph>
            Read more and see how you invite your friends to a party with Queue
            <span role="img" aria-label="pointing-right">
              👉
            </span>
          </BoldParagraph>
          <StyledLink to="/invite-guide">
            <Button>Read guide</Button>
          </StyledLink>
        </More>

        <BlogFooter />
      </Wrapper>
    </PageWrapper>
  )
}

export default MusicBlog

// Page Wrapper
const PageWrapper = styled.div`
  width: 100%;
  height: 100%;

  display: flex;
  flex-direction: row;
  justify-content: center;
`

// Content wrapper
const Wrapper = styled.div`
  max-width: 700px;
  height: auto;

  background-color: inherit;

  margin: 2.5rem 2.5rem 0 2.5rem;
`

// Blog header and subheader
const BlogHeader = styled.h1`
  font-family: "Avenir";
  font-size: 2rem;
  font-weight: 600;

  line-height: 1.3;

  margin-bottom: 0.7rem;

  @media (min-width: 500px) {
    font-size: 2.5rem;
  }

  @media (min-width: 650px) {
    font-size: 2.8rem;
  }
`

const Yellow = styled.span`
  color: #fdde68;
`

const Teaser = styled.p`
  font-family: "Avenir";
  font-size: 1.3rem;
  font-style: italic;
  line-height: 1.2;

  margin-bottom: 3rem;

  @media (min-width: 500px) {
    font-size: 1.4rem;
  }

  @media (min-width: 650px) {
    font-size: 1.6rem;
  }
  @media (min-width: 750px) {
    font-size: 1.8rem;
  }
`

// -------------

// Section styles
const Section = styled.section`
  max-width: inherit;
  min-height: 400px;

  height: auto;

  margin-bottom: 2.5rem;

  display: flex;
  flex-direction: column;
`

// Sticking header and bar together
const HeaderContainer = styled.div`
  height: auto;

  display: flex;
  flex-direction: column;

  justify-content: space-between;

  margin-bottom: 1rem;
`

const Header = styled.h1`
  font-family: "Avenir";
  font-size: 1.7rem;
  font-weight: 700;

  line-height: 1;

  margin-bottom: 0.6rem;

  @media (min-width: 500px) {
    font-size: 2rem;
  }

  @media (min-width: 650px) {
    font-size: 2.2rem;
  }
`

const YellowBar = styled.div`
  width: 80px;
  height: 15px;

  background-color: #fdde68;

  @media (min-width: 500px) {
    width: 100px;
  }

  @media (min-width: 650px) {
    width: 115px;
  }
`

const Paragraph = styled.p`
  font-family: "Avenir";
  font-size: 1rem;
  font-style: normal;

  line-height: 1.3;

  margin-bottom: 1.5rem;

  @media (min-width: 500px) {
    font-size: 1rem;
  }

  @media (min-width: 650px) {
    font-size: 1.2rem;

    margin-bottom: 2rem;
  }

  @media (min-width: 750px) {
    font-size: 1.4rem;
  }
`

const ImageContainer = styled.div`
  width: 100%;
  height: auto;

  display: flex;
  flex-direction: row;

  align-items: center;
  justify-content: center;
`

const Image = styled.div`
  width: 250px;
  height: 250px;
  background-repeat: no-repeat;
  background-size: contain;

  @media (min-width: 500px) {
    width: 280px;
    height: 280px;
  }

  @media (min-width: 650px) {
    width: 320px;
    height: 320px;
  }

  @media (min-width: 750px) {
    width: 370px;
    height: 370px;
  }
`

// -------------

// Images extended from Image
const Image1 = styled(Image)`
  background-image: url(${hostparty});
`

const Image2 = styled(Image)`
  background-image: url(${queuesongs});
`

const Image3 = styled(Image)`
  background-image: url(${thequeue});
`

// Link to next guide
const More = styled.div`
  width: 100%;
  height: auto;

  display: flex;
  flex-direction: column;
`

const BoldParagraph = styled.p`
  font-family: "Avenir";
  font-size: 1.2rem;

  line-height: 1.3;

  margin-top: 1rem;
  margin-bottom: 1rem;

  @media (min-width: 500px) {
    font-size: 1.4rem;
  }

  @media (min-width: 650px) {
    font-size: 1.6rem;
  }
`

const Button = styled.div`
  width: 150px;
  height: 40px;

  background-color: #fdde68;

  -webkit-box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.75);
  box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.75);

  border-radius: 5px;

  display: flex;
  align-items: center;
  justify-content: center;

  @media (min-width: 500px) {
    width: 200px;
    height: 45px;
  }

  @media (min-width: 650px) {
    width: 250px;
    height: 50px;
  }
`

const StyledLink = styled(props => <Link {...props} />)`
  font-family: "Avenir";
  font-size: 1.2rem;
  text-decoration: none;

  color: black;

  @media (min-width: 650px) {
    font-size: 1.4rem;
  }
`
